// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-artist-detail-js": () => import("/opt/build/repo/src/templates/artist-detail.js" /* webpackChunkName: "component---src-templates-artist-detail-js" */),
  "component---src-templates-record-detail-js": () => import("/opt/build/repo/src/templates/record-detail.js" /* webpackChunkName: "component---src-templates-record-detail-js" */),
  "component---src-templates-review-detail-js": () => import("/opt/build/repo/src/templates/review-detail.js" /* webpackChunkName: "component---src-templates-review-detail-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("/opt/build/repo/src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-records-js": () => import("/opt/build/repo/src/pages/records.js" /* webpackChunkName: "component---src-pages-records-js" */),
  "component---src-pages-reviews-js": () => import("/opt/build/repo/src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */)
}

